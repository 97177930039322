import { InputBase, Box, styled, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSearchedProducts } from '../../../redux/userHandle';

const Search = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false); // State to manage loader

    const handleSearch = () => {
        setLoading(true); // Show loader
        setTimeout(() => {
            dispatch(getSearchedProducts("searchProduct", searchTerm));

            if (location.pathname !== "/ProductSearch") {
                navigate("/ProductSearch");
            }
            setLoading(false); // Hide loader after search completes
        }, 2000); // 2-second delay
    };

    return (
        <SearchContainer>
            <InputSearchBase
                placeholder="Search Books"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSearch();
                    }
                }}
                disabled={loading} // Disable input while loading
            />
            <SearchIconWrapper>
                {loading ? <CircularProgress size={24} /> : <SearchIcon sx={{ color: "#01411c" }} />}
            </SearchIconWrapper>
        </SearchContainer>
    );
};

const SearchContainer = styled(Box)`
  border-radius: 5px;
  margin-left: 10%;
  padding: 6px 0px;
  width: 70%;
  background-color: #fff;
  display: flex;
`;

const SearchIconWrapper = styled(Box)`
  margin-left: auto;
  padding: 5px;
  display: flex;
  color: blue;
`;

const InputSearchBase = styled(InputBase)`
  font-size: unset;
  width: 100%;
  padding-left: 20px;
`;

export default Search;
