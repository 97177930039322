import React, { useEffect, useState } from "react";
import { 
    Container, 
    Typography, 
    Grid, 
    Button, 
    Tabs, 
    Tab, 
    Paper, 
    Skeleton
} from "@mui/material";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import image from "../../../assets/images.png";
import "../../slideproduct.css";

const News = () => {
    const [newsList, setNewsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);

    const categories = [
        "Admissions", 
        "Exams & Results", 
        "Conferences & Events", 
        "Book Reviews"
    ];

    useEffect(() => {
        fetch("https://backend.alamkitab.com/api/news")
            .then((res) => res.json())
            .then((data) => {
                setNewsList(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching news:", error);
                setLoading(false);
            });
    }, []);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const renderNewsCard = (newsItem) => {
        const sanitizedDescription = DOMPurify.sanitize(newsItem.description);

        return (
            <Grid item xs={12} key={newsItem._id}>
                <div className="container_h">
                    <div className="card_h">
                        <div className="child1_h">
                            <img 
                                src={newsItem.image || image} 
                                alt={newsItem.title} 
                                className="img-section_h" 
                            />
                        </div>
                        <div className="child2_h">
                            <div className="title_h">{newsItem.title}</div>
                            <div className="content_h">
                                Published on: {dayjs(newsItem.createdAt).format("MMMM D, YYYY")}
                            </div>
                            <p 
                                className="additional-text_h description_h" 
                                dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                            />
                            <Button
                                component={Link}
                                to={`/news/${newsItem._id}`}
                                size="small"
                                color="primary"
                                variant="outlined"
                            >
                                Read More
                            </Button>
                        </div>
                    </div>
                </div>
            </Grid>
        );
    };

    return (
        <Container maxWidth="xl" sx={{ py: 4 }}>
            <Typography 
                variant="h4" 
                component="h1" 
                gutterBottom 
                sx={{ textAlign: "center", marginBottom: 4, fontWeight: "bold" }}
            >
                News & Updates
            </Typography>
            <Paper elevation={0} sx={{ backgroundColor: "transparent", marginBottom: 3 }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 3 }}
                >
                    {categories.map((category, index) => (
                        <Tab 
                            key={category} 
                            label={category} 
                            sx={{ textTransform: "none", fontWeight: activeTab === index ? "bold" : "normal" }} 
                        />
                    ))}
                </Tabs>
            </Paper>

            <Grid container spacing={3}>
                {categories.map((category, index) => (
                    activeTab === index && (
                        <Grid item xs={12} key={category}>
                            <Typography 
                                variant="h5" 
                                sx={{ marginBottom: 2, fontWeight: "bold" }}
                            >
                                {category}
                            </Typography>
                            {loading ? (
                                <Grid container spacing={2}>
                                    {[1, 2, 3].map((item) => (
                                        <Grid item xs={12} key={item}>
                                            <Skeleton 
                                                variant="rectangular" 
                                                height={150} 
                                                sx={{ borderRadius: 2 }} 
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <Grid container spacing={2}>
                                    {newsList
                                        .filter((news) => news.category === category)
                                        .length > 0 ? (
                                        newsList
                                            .filter((news) => news.category === category)
                                            .map((newsItem) => renderNewsCard(newsItem))
                                    ) : (
                                        <Grid item xs={12}>
                                            <Typography 
                                                variant="body1" 
                                                color="text.secondary" 
                                                align="center"
                                            >
                                                No news available in this category.
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    )
                ))}
            </Grid>
        </Container>
    );
};

export default News;
