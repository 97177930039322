import { useDispatch, useSelector } from "react-redux";
import { getSpecificProducts } from "../../../redux/userHandle"; 
import { useEffect, useState } from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { BlueButton, DarkRedButton, GreenButton } from "../../../utils/buttonStyles";
import TableTemplate from "../../../components/TableTemplate";
import { useNavigate } from "react-router-dom";

const OutForDeliverySection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentUser, specificProductData, responseSpecificProducts } = useSelector(state => state.user);

    // State to hold customer data fetched from /getAllCustomers
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        dispatch(getSpecificProducts(currentUser._id, "getOrderedProductsBySeller"));
        // console.log('Orders data:', specificProductData); // Log orders data for debugging
    }, [dispatch, currentUser._id]);
    

    // Fetch all customers when component mounts
    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await fetch('https://backend.alamkitab.com/getAllCustomers');
                if (!response.ok) throw new Error('Failed to fetch customers');
    
                const data = await response.json();
                // console.log('Fetched customers:', data);  // Log customer data for debugging
                setCustomers(data); // Store customers in the state
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };
    
        fetchCustomers();
    }, []);
    

    // Helper function to get customer name by ID
    const getCustomerName = (customerId) => {
        const customer = customers.find(c => c.customerID === customerId);
        return customer ? customer.customerName : 'Unknown';
    };


    const productsColumns = [
        { id: 'orderNumber', label: 'Order Number', minWidth: 150 },
        { id: 'customerName', label: 'Customer Name', minWidth: 150 }, // Added Customer Name column
        { id: 'totalPrice', label: 'Total Price', minWidth: 100 },
        { id: 'status', label: 'Order Status', minWidth: 150 },
        { id: 'paidAt', label: 'Ordered At', minWidth: 200 }
    ];

    const productsRows = Array.isArray(specificProductData) && specificProductData.length > 0
    ? [...specificProductData]  // Create a shallow copy of the array
        .sort((a, b) => new Date(b.paidAt) - new Date(a.paidAt))  // Sort by date in descending order
        .map((order) => ({
            orderNumber: order.orderNumber,
            customerName: getCustomerName(order.buyer), // Use helper function to get customer name
            productName: order.orderedProducts[0]?.productName || "N/A",
            quantity: order.orderedProducts[0]?.quantity || 0,
            totalPrice: order.totalPrice + 100 || 0,
            category: order.orderedProducts[0]?.category || "N/A",
            status: order.orderStatus,
            paidAt: new Date(order.paidAt).toLocaleString(),
            id: order._id,
            customerId: order.buyer
        }))
    : [];




const ProductsButtonHaver = ({ row }) => {
    const [selectedStatus, setSelectedStatus] = useState(row.status);

    // Function to handle status change
    const handleStatusChange = async (orderId, newStatus) => {
        try {
            const response = await fetch(`https://backend.alamkitab.com/api/orders/${orderId}/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus })
            });

            if (!response.ok) {
                throw new Error('Failed to update order status');
            }

            const data = await response.json();
            // Update local status state
            setSelectedStatus(newStatus);

            // Re-fetch or update order list after status change
            dispatch(getSpecificProducts(currentUser._id, "getOrderedProductsBySeller"));
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    // Disable previous statuses once they are selected
    const availableStatuses = ['Processing', 'Packed', 'Shipped', 'Delivered'];
    const statusOptions = availableStatuses.slice(availableStatuses.indexOf(row.status));

    return (
        <>
            <Select
                value={selectedStatus}
                onChange={(e) => handleStatusChange(row.id, e.target.value)}
                disabled={selectedStatus === 'Delivered'} // Disable if already delivered
            >
                {statusOptions.map((status) => (
                    <MenuItem key={status} value={status}>
                        {status}
                    </MenuItem>
                ))}
            </Select>

            <BlueButton
                onClick={() => {
                    navigate("/Seller/orders/customers/" + row.customerId);
                }}
            >
                View Order
            </BlueButton>
        </>
    );
};


    return (
        <>
            {responseSpecificProducts ? (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <GreenButton
                        variant="contained"
                        onClick={() => navigate("/Seller/addproduct")}
                    >
                        Add Products
                    </GreenButton>
                </Box>
            ) : (
                <>
                    <Typography variant="h5" gutterBottom>
                        Orders List:
                    </Typography>

                    <TableTemplate buttonHaver={ProductsButtonHaver} columns={productsColumns} rows={productsRows} />
                </>
            )}
        </>
    );
};

export default OutForDeliverySection;
