import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './userSlice';
import { promoReducer } from './promoReducer';
import productHistoryReducer from './slices/printHistorySlice';
import productReducer from './slices/productSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        promo: promoReducer,
        productHistory: productHistoryReducer,
        product: productReducer,
    }
});

export default store;