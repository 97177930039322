import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for fetching paginated products
export const getProducts = createAsyncThunk(
  'products/getProducts',
  async ({ page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${import.meta.env.REACT_APP_BASE_URL}/getProducts?page=${page}&limit=${limit}`);
      return response.data; // Contains products, totalProducts, totalPages, currentPage
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch products'
      );
    }
  }
);

// Async thunk for fetching product details
export const getProductDetail = createAsyncThunk(
  'products/getProductDetail',
  async (productId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${import.meta.env.REACT_APP_BASE_URL}/getProductDetail/${productId}`);
      return response.data; // Contains product detail
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch product details'
      );
    }
  }
);

// Async thunk for searching products by category
export const searchProductByCategory = createAsyncThunk(
  'products/searchProductByCategory',
  async ({ categoryKey, page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${import.meta.env.REACT_APP_BASE_URL}/searchProductbyCategory/${categoryKey}?page=${page}&limit=${limit}`
      );
      return response.data; // Contains products, totalProducts, totalPages, currentPage
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch products'
      );
    }
  }
);

// Redux slice
const productsSlice = createSlice({
  name: 'products',
  initialState: {
    productData: [], // List of products
    productDetail: null, // Detailed product information
    totalProducts: 0,
    totalPages: 0,
    currentPage: 1,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle getProducts
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.productData = action.payload.products;
        state.totalProducts = action.payload.totalProducts;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle getProductDetail
    builder
      .addCase(getProductDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.productDetail = action.payload; // Save product details
      })
      .addCase(getProductDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle searchProductByCategory
    builder
      .addCase(searchProductByCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchProductByCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.products;
        state.totalProducts = action.payload.totalProducts;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(searchProductByCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer
export default productsSlice.reducer;
