import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Container,
    Typography,
    Stack,
    Button,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Skeleton,
} from '@mui/material';
import { fetchProductHistory } from '../../../redux/slices/printHistorySlice';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/helperFunctions';

const renderSkeleton = () => (
    <TableBody>
        {[...Array(2)].map((_, index) => (
            <TableRow key={index}>
                <TableCell><Skeleton variant="text" width={100} /></TableCell>
                <TableCell><Skeleton variant="text" width={120} /></TableCell>
                <TableCell><Skeleton variant="text" width={80} /></TableCell>
                <TableCell><Skeleton variant="text" width={60} /></TableCell>
                <TableCell><Skeleton variant="text" width={100} /></TableCell>
                <TableCell><Skeleton variant="text" width={100} /></TableCell>
                <TableCell><Skeleton variant="rectangular" width={100} height={40} /></TableCell>
            </TableRow>
        ))}
    </TableBody>
);

const PrintHistory = () => {
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.user);
    const { productHistory, loading } = useSelector((state) => state.productHistory);

    const [open, setOpen] = useState(null);
    const [selectedOption, setSelectedOption] = useState('newest');
    const [sortedProductData, setSortedProductData] = useState([]);

    useEffect(() => {
        if (currentUser) {
            dispatch(fetchProductHistory(currentUser._id));
        }
    }, [dispatch, currentUser]);

    useEffect(() => {
        if (productHistory && productHistory.length > 0) {
            const sortedData = [...productHistory].sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return selectedOption === 'newest' ? dateB - dateA : dateA - dateB;
            });
            setSortedProductData(sortedData);
        }
    }, [productHistory, selectedOption]);

    const handleOpen = (event) => setOpen(event.currentTarget);
    const handleClose = () => setOpen(null);
    const handleMenuItemClick = (value) => {
        setSelectedOption(value);
        handleClose();
    };

    return (
        <Container sx={{ padding: 4 }}>
            <Typography sx={{ fontSize: 40, textAlign: 'center', mb: 4 }}>My Print Documents</Typography>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mb: 4 }}>
                <Button color="inherit" onClick={handleOpen}>
                    Sort By: {selectedOption === 'newest' ? 'Newest' : 'Oldest'}
                </Button>
                <Menu
                    anchorEl={open}
                    open={!!open}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MenuItem onClick={() => handleMenuItemClick('newest')}>Newest</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('oldest')}>Oldest</MenuItem>
                </Menu>
            </Stack>

            <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', mb: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Document ID</TableCell>
                            <TableCell>Paper Type</TableCell>
                            <TableCell>Price (with Delivery)</TableCell>
                            <TableCell>No. Pages</TableCell>
                            <TableCell>Single/Double Side</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Your Document</TableCell>
                        </TableRow>
                    </TableHead>
                    {loading ? (
                        renderSkeleton()
                    ) : (
                        <TableBody>
                            {sortedProductData.length > 0 ? (
                                sortedProductData.map((order) => (
                                    <TableRow key={order._id}>
                                        <TableCell>{order.pdfNumber}</TableCell>
                                        <TableCell>{order.paperQuality}</TableCell>
                                        <TableCell>{order.totalCost}</TableCell>
                                        <TableCell>{order.numPages}</TableCell>
                                        <TableCell>{order.side}</TableCell>
                                        <TableCell>{formatDate(order.createdAt)}</TableCell>
                                        <TableCell>
                                            <Button variant="contained">
                                                <Link
                                                    to={`https://backend.alamkitab.com/uploads/${order.uploadedFile}`}
                                                    target="_blank"
                                                    style={{ textDecoration: 'none', color: 'white' }}
                                                >
                                                    View Document
                                                </Link>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No Orders Till Now
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Container>
    );
};

export default PrintHistory;
