import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Box, Button, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomer } from '../../../redux/userHandle';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';

const ShippingPage = ({ handleNext, profile }) => {
  const { currentUser } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  let shippingData = currentUser.shippingData;

  const cities = [
    'Karachi', 'Lahore', 'Islamabad', 'Bahawalpur', 'Faisalabad', 'Gujranwala', 
    'Multan', 'Sargodha', 'Rawalpindi', 'Sialkot', 'Hyderabad', 'Sukkur', 
    'Mirpurkhas', 'Peshawar', 'Quetta'
  ];

  const states = ['Sindh', 'Punjab', 'Balochistan', 'KPK'];

  const [formData, setFormData] = useState({
    address: '',
    city: '',
    state: '',
    country: '',
    pinCode: '',
    phoneNo: '',
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showTab, setShowTab] = useState(false);

  useEffect(() => {
    if (shippingData) {
      setFormData({
        address: shippingData.address || '',
        city: shippingData.city || '',
        pinCode: shippingData.pinCode || '',
        country: shippingData.country || '',
        state: shippingData.state || '',
        phoneNo: shippingData.phoneNo || '',
      });
    }
  }, [shippingData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateInputs = () => {
    const newErrors = {};

    if (!formData.address.trim()) newErrors.address = 'Address is required';
    if (!formData.city.trim()) newErrors.city = 'City is required';
    if (!formData.state.trim()) newErrors.state = 'State is required';
    if (!formData.country.trim()) newErrors.country = 'Country is required';
    if (!formData.phoneNo.trim() || isNaN(formData.phoneNo) || formData.phoneNo.length !== 11) {
      newErrors.phoneNo = 'Phone Number is required and should be an 11-digit number';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateShippingData = async () => {
    if (validateInputs()) {
      setLoading(true);
      const updatedUser = { ...currentUser, shippingData: formData };
      await dispatch(updateCustomer(updatedUser, currentUser._id));
      setLoading(false);
      setShowTab(false); // Hide the edit form after submission
    }
  };

  const handleSubmit = async () => {
    if (validateInputs()) {
      setLoading(true);
      await updateShippingData();
      setLoading(false);
      handleNext();
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <React.Fragment>
          {/* If no shippingData or edit button is clicked, show the form */}
          {showTab || !shippingData || Object.keys(shippingData).length === 0 ? (
            <Box component="form" noValidate autoComplete="off">
              <TextField
                label="Address"
                fullWidth
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                error={Boolean(errors.address)}
                helperText={errors.address}
                sx={{ mb: 2 }}
              />
              <TextField
                label="City"
                fullWidth
                name="city"
                select
                value={formData.city}
                onChange={handleInputChange}
                error={Boolean(errors.city)}
                helperText={errors.city}
                sx={{ mb: 2 }}
              >
                {cities.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="State"
                fullWidth
                name="state"
                select
                value={formData.state}
                onChange={handleInputChange}
                error={Boolean(errors.state)}
                helperText={errors.state}
                sx={{ mb: 2 }}
              >
                {states.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Country"
                fullWidth
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                error={Boolean(errors.country)}
                helperText={errors.country}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Phone Number"
                fullWidth
                type="text"
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleInputChange}
                error={Boolean(errors.phoneNo)}
                helperText={errors.phoneNo}
                sx={{ mb: 2 }}
              />

              {/* Save and Cancel buttons */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  onClick={() => setShowTab(false)}
                  sx={{ mt: 3, ml: 1 }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={updateShippingData}
                  sx={{ mt: 3, ml: 1 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              {/* Show existing shipping data */}
              <Typography variant="h6">Address: {shippingData.address}</Typography>
              <Typography variant="h6">City: {shippingData.city}</Typography>
              <Typography variant="h6">State: {shippingData.state}</Typography>
              <Typography variant="h6">Country: {shippingData.country}</Typography>
              <Typography variant="h6">Mobile Number: {shippingData.phoneNo}</Typography>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  onClick={() => setShowTab(true)}
                  sx={{ mt: 3, ml: 1 }}
                >
                  Change
                </Button>

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  Next
                </Button>
              </Box>
            </>
          )}
        </React.Fragment>
      )}
    </React.Fragment>


  );
};

export default ShippingPage;
