import React, { useEffect, useState } from "react";
import { Box, Grid, Container, Pagination } from "@mui/material";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  BasicButton,
  BrownButton,
  DarkRedButton,
  IndigoButton,
} from "../../../utils/buttonStyles";
import { useNavigate } from "react-router-dom";
import { deleteStuff, getProductsbySeller } from "../../../redux/userHandle";
import SpeedDialTemplate from "../../../components/SpeedDialTemplate.jsx";
import AddCardIcon from "@mui/icons-material/AddCard";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import AlertDialogSlide from "../../../components/AlertDialogSlide";
import altImage from "../../../assets/altimg.png"; // Placeholder image

const ShowProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    currentUser,
    currentRole,
    loading,
    sellerProductData,
    currentPage,
    totalPages,
  } = useSelector((state) => state.user);

  const sellerID = currentUser._id;

  const [dialog, setDialog] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    dispatch(getProductsbySeller(sellerID, currentPage || 1, 20));
  }, [dispatch, sellerID, currentPage]);

  const deleteHandler = (deleteID, address) => {
    dispatch(deleteStuff(deleteID, address)).then(() => {
      dispatch(getProductsbySeller(sellerID, currentPage, 20));
    });
  };

  const deleteAllProducts = () => {
    deleteHandler(sellerID, "DeleteProducts");
  };

  const handlePageChange = (event, value) => {
    dispatch(getProductsbySeller(sellerID, value, 20));
  };

  const actions = [
    {
      icon: <AddCardIcon color="primary" />,
      name: "Add New Product",
      action: () => navigate("/Seller/addproduct"),
    },
    {
      icon: <DeleteIcon color="error" />,
      name: "Delete All Products",
      action: () => {
        setDialog("Do you want to delete all products?");
        setShowDialog(true);
      },
    },
  ];

  const shopcartActions = [
    ...actions,
    {
      icon: <UploadIcon color="success" />,
      name: "Upload New Product",
      action: () => navigate("/Seller/uploadproducts"),
    },
  ];

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {sellerProductData?.length > 0 ? (
            <ProductGrid container spacing={3}>
              {sellerProductData.map((data, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ProductCard>
                    <ProductImage
                      src={data.productImage || altImage}
                      alt={data.productName || "Product"}
                    />
                    <ProductInfo>
                      <ProductName>{data.productName}</ProductName>
                      <PriceCost>Rs{data.price.cost}</PriceCost>
                      <ButtonContainer>
                        <DarkRedButton
                          onClick={() =>
                            deleteHandler(data._id, "DeleteProduct")
                          }
                        >
                          Delete
                        </DarkRedButton>
                        <BasicButton
                          onClick={() =>
                            navigate(`/Seller/products/product/${data._id}`)
                          }
                        >
                          View
                        </BasicButton>
                      </ButtonContainer>
                    </ProductInfo>
                  </ProductCard>
                </Grid>
              ))}
            </ProductGrid>
          ) : (
            <div>No Products Available</div>
          )}

          <Container
            sx={{
              mt: 4,
              mb: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="secondary"
            />
          </Container>

          {currentRole === "Shopcart" ? (
            <SpeedDialTemplate actions={shopcartActions} />
          ) : (
            <SpeedDialTemplate actions={actions} />
          )}
        </>
      )}
      <AlertDialogSlide
        dialog={dialog}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        taskHandler={deleteAllProducts}
      />
    </>
  );
};

export default ShowProducts;

// Styled components remain unchanged


const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  background-color: #f0f0f0;
`;

const ProductInfo = styled.div`
  padding: 16px;
  text-align: center;
`;

const ProductGrid = styled(Grid)`
  padding: 16px;
  display: flex;
  justify-content: center;
`;

const ProductName = styled.h3`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 8px;
`;

const PriceCost = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff5722;
  margin: 8px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;
