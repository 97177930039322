import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { 
    Container, 
    Typography, 
    Box, 
    Button, 
    Alert, 
    CircularProgress, 
    Paper 
} from "@mui/material";
import DOMPurify from "dompurify";
import './newsupdates.css'

const NewsDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [newsItem, setNewsItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) {
            setError("No news ID provided");
            setLoading(false);
            return;
        }

        fetch(`https://backend.alamkitab.com/api/news/${id}`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Failed to fetch news details");
                }
                return res.json();
            })
            .then((data) => {
                if (!data) {
                    throw new Error("No news item found");
                }
                setNewsItem(data);
                setLoading(false);
            })
            .catch((err) => {
                console.error("Error fetching news details:", err);
                setError(err.message);
                setLoading(false);
            });
    }, [id]);

    if (loading) {
        return (
            <Container maxWidth="md">
                <Box 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center" 
                    minHeight="50vh"
                >
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="md">
                <Alert 
                    severity="error"
                    sx={{ marginTop: 4 }}
                    action={
                        <Button color="inherit" size="small" onClick={() => navigate(-1)}>
                            Go Back
                        </Button>
                    }
                >
                    {error}
                </Alert>
            </Container>
        );
    }

    if (!newsItem) {
        return (
            <Container maxWidth="md">
                <Alert 
                    severity="warning"
                    sx={{ marginTop: 4 }}
                    action={
                        <Button color="inherit" size="small" onClick={() => navigate(-1)}>
                            Go Back
                        </Button>
                    }
                >
                    No news item found
                </Alert>
            </Container>
        );
    }

    const sanitizedDescription = DOMPurify.sanitize(newsItem.description);

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
                {newsItem.image && (
                    <Box sx={{ mb: 3, borderRadius: 2, overflow: "hidden" }}>
                        <img 
                            src={newsItem.image} 
                            alt={newsItem.title} 
                            style={{ 
                                width: "100%", 
                                height: "auto", 
                                objectFit: "cover", 
                                borderRadius: "8px" 
                            }}
                        />
                    </Box>
                )}

                <Typography 
                    variant="h4" 
                    component="h1" 
                    gutterBottom 
                    sx={{ 
                        fontWeight: "bold", 
                        marginBottom: 3, 
                        textAlign: "center", 
                        color: "primary.main" 
                    }}
                >
                    {newsItem.title}
                </Typography>

                <Box 
                    sx={{ 
                        marginBottom: 2,
                        color: "text.primary"
                    }} 
                    className="news-description"
                    dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                />

                <Typography 
                    variant="subtitle1" 
                    color="text.secondary"
                    sx={{ 
                        marginTop: 2, 
                        fontWeight: "bold" 
                    }}
                >
                    <strong>Category:</strong> {newsItem.category}
                </Typography>

                <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ 
                        marginTop: 1 
                    }}
                >
                    <strong>Published on:</strong> {new Date(newsItem.createdAt).toLocaleDateString()}
                </Typography>

                <Box sx={{ marginTop: 3 }}>
                    <Button 
                        variant="contained" 
                        onClick={() => navigate(-1)} 
                        sx={{
                            backgroundColor: "#006001",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "darkgreen",
                            },
                            padding: "10px 20px",
                        }}
                    >
                        Back to News
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default NewsDetails;
