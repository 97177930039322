import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, FormControlLabel, RadioGroup, Radio, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addStuff } from '../../../redux/userHandle';
import { useNavigate, useParams } from 'react-router-dom';
import Popup from '../../../components/Popup';
import { fetchProductDetailsFromCart, removeAllFromCart, removeSpecificProduct } from '../../../redux/userSlice';
import { BlueButton, GreenButton } from '../../../utils/buttonStyles';

const PaymentForm = ({ handleBack }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { status, currentUser, productDetailsCart } = useSelector(state => state.user);
    const params = useParams();
    const productID = params.id;

    const [showPopup, setShowPopup] = useState(false);
    const [message, setMessage] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("Cash on Delivery");
    const [confirmOpen, setConfirmOpen] = useState(false); // State for confirmation dialog

    useEffect(() => {
        if (productID) {
            dispatch(fetchProductDetailsFromCart(productID));
        }
    }, [productID, dispatch]);

    const singleProductQuantity = productDetailsCart && productDetailsCart.quantity;
    const totalsingleProductPrice = productDetailsCart && productDetailsCart.price && productDetailsCart.price.cost * productDetailsCart.quantity;

    const multiOrderData = {
        buyer: currentUser._id,
        shippingData: currentUser.shippingData,
        orderedProducts: currentUser.cartDetails,
        paymentInfo: { method: paymentMethod },
        productsQuantity: currentUser.cartDetails.reduce((total, item) => total + item.quantity, 0),
        totalPrice: currentUser.cartDetails.reduce((total, item) => total + (item.quantity * item.price.cost), 0),
    };

    const singleOrderData = {
        buyer: currentUser._id,
        shippingData: currentUser.shippingData,
        orderedProducts: productDetailsCart,
        paymentInfo: { method: paymentMethod },
        productsQuantity: singleProductQuantity,
        totalPrice: totalsingleProductPrice,
    };

    const handleOrderConfirmation = () => {
        setConfirmOpen(true); // Open the confirmation dialog
    };

    const handleConfirmOrder = async () => {
        try {
            if (productID) {
                dispatch(addStuff("newOrder", singleOrderData));
                dispatch(removeSpecificProduct(productID));
            } else {
                dispatch(addStuff("newOrder", multiOrderData));
                dispatch(removeAllFromCart());
            }
            setConfirmOpen(false); // Close the dialog
            navigate('/Aftermath');
        } catch (error) {
            console.error("Error placing order:", error);
        }
    };

    const handleCancelOrder = () => {
        setConfirmOpen(false); // Close the dialog
    };

    useEffect(() => {
        if (status === 'added') {
            navigate('/Aftermath');
        } else if (status === 'failed') {
            setMessage("Order Failed");
            setShowPopup(true);
        } else if (status === 'error') {
            setMessage("Network Error");
            setShowPopup(true);
        }
    }, [status, navigate]);

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Payment Method
            </Typography>
            <form onSubmit={(e) => { e.preventDefault(); handleOrderConfirmation(); }}>
                <RadioGroup
                    aria-label="payment-method"
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                >
                    <FormControlLabel
                        value="Cash on Delivery"
                        control={<Radio />}
                        label="Cash on Delivery"
                    />
                    <FormControlLabel
                        value="Online payment on delivery (Easypaisa / Jazzcash / IBFT)"
                        control={<Radio />}
                        label="Online payment on delivery (Easypaisa / Jazzcash / IBFT)"
                    />
                </RadioGroup>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <BlueButton onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Back
                    </BlueButton>
                    <GreenButton
                        variant="contained"
                        type='submit'
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Place order
                    </GreenButton>
                </Box>
            </form>

            {/* Confirmation Dialog */}
            <Dialog
                open={confirmOpen}
                onClose={handleCancelOrder}
            >
                <DialogTitle>Confirm Order</DialogTitle>
                <DialogContent>
                    <Typography>Do you want to confirm your order?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelOrder} color="primary">
                        No
                    </Button>
                    <Button onClick={handleConfirmOrder} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Popup message={message} setShowPopup={setShowPopup} showPopup={showPopup} />
        </React.Fragment>
    );
};

export default PaymentForm;
