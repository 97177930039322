import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, MenuItem, Typography, Box } from '@mui/material';

const BookRequestForm = () => {
    const [formData, setFormData] = useState({
        reason: '',
        description: '',
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [submitted, setSubmitted] = useState(false);

    const reasons = [
        // { value: 'Want to sell Used Book', label: 'Want to sell Used Book' },
        { value: 'New Request', label: 'New Request' },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://backend.alamkitab.com/api/request-book', formData);
            console.log(response.data); // Log response for debugging
            setSubmitted(true);
        } catch (error) {
            console.error('Error submitting the form', error);
        }
    };

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                minHeight: '100vh', 
                padding: 2 
            }}
        >
            {submitted ? (
                <Box sx={{
                    padding: 2,
                    margin: '20px auto',
                    backgroundColor: '#e0f7fa',
                    border: '1px solid #00695c',
                    borderRadius: '8px',
                    textAlign: 'center'
                }}>
                    <Typography variant="h5" color="#004d40">
                        Request Submitted! We will contact you soon.
                    </Typography>
                </Box>
            ) : (
                <form onSubmit={handleSubmit} style={{ maxWidth: '500px', width: '100%' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Request a Book
                    </Typography>
                    <TextField
                        select
                        label="Reason for Request"
                        name="reason"
                        value={formData.reason}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                    >
                        {reasons.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Book Name (Title/Author)"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Your Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Your Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Your Phone Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Your Message or Queries / Book Description"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={3}
                        margin="normal"
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Send Request
                    </Button>
                </form>
            )}
        </Box>
    );
};

export default BookRequestForm;
