import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';

const PricingModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* Button to open the modal */}
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Show Pricing Structure
      </Button>

      {/* Modal */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', color: '#006001' }}>
          Pricing Structure
        </DialogTitle>
        <DialogContent>
          {/* Pricing Table */}
          <TableContainer component={Paper} style={{ width: '100%', margin: '0 auto' }}>
            <Table>
              <TableHead style={{ backgroundColor: '#006001' }}>
                <TableRow>
                  <TableCell style={{ color: '#fff', fontWeight: 'bold' }}>Type</TableCell>
                  <TableCell style={{ color: '#fff', fontWeight: 'bold' }}>Single-sided (per page)</TableCell>
                  <TableCell style={{ color: '#fff', fontWeight: 'bold' }}>Double-sided (per page)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Black and White</TableCell>
                  <TableCell>Rs 10</TableCell>
                  <TableCell>Rs 15</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Color</TableCell>
                  <TableCell>Rs 15</TableCell>
                  <TableCell>Rs 20</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Delivery Charges Table */}
          <div >
            <TableContainer component={Paper} style={{ width: '100%', margin: '0 auto', marginTop:'30px' }}>
              <Table>
                <TableHead style={{ backgroundColor: '#006001' }}>
                  <TableRow>
                    <TableCell style={{ color: '#fff', fontWeight: 'bold' }}>Delivery Charges:</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Normal (2-3 Days)</TableCell>
                    <TableCell>Rs 50</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Urgent (1 Day)</TableCell>
                    <TableCell>Rs 150</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PricingModal;
