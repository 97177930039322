import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { BookOnlineOutlined, Login, Logout, MenuRounded, PrintOutlined, Shop2, Store } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Badge, Divider, Drawer, ListItemIcon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';
import { NavLogo } from '../utils/styles';
import Cart from './customer/components/Cart';
import { updateCustomer } from '../redux/userHandle';
import { useTheme, useMediaQuery } from '@mui/material'; // Import useTheme and useMediaQuery

const Navbar = () => {
    const theme = useTheme(); // Use the theme
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Check if mobile
    const [mobileMenuAnchor, setMobileMenuAnchor] = React.useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const { currentUser, currentRole } = useSelector(state => state.user);
    const totalQuantity = currentUser && currentUser.cartDetails && currentUser.cartDetails.reduce((total, item) => total + item.quantity, 0);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (currentRole === "Customer") {
            dispatch(updateCustomer(currentUser, currentUser._id));
        }
    }, [currentRole, currentUser, dispatch]);

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [isCartOpen, setIsCartOpen] = React.useState(false);

    const handleOpenCart = () => {
        setIsCartOpen(true);
    };

    const handleCloseCart = () => {
        setIsCartOpen(false);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const homeHandler = () => {
        navigate("/");
    };

    const menuItems = [
        { text: "Bookstore", onClick: () => navigate("/bookstore") },
        { text: "Print Documents", onClick: () => navigate("/request-ur-document-for-printing") },
        { text: "Request a Book", onClick: () => navigate("/request-a-book") },
        { text: "News & Updates", onClick: () => navigate("/news") },
      ];
    
      const handleMobileMenuOpen = (event) => {
        setMobileMenuAnchor(event.currentTarget);
      };
    
      const handleMobileMenuClose = () => {
        setMobileMenuAnchor(null);
      };
    
      const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
          return;
        }
        setIsDrawerOpen(open);
      };
    
      const drawerMenu = (
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={item.onClick}>
              {item.text}
            </MenuItem>
          ))}
            <IconButton
                component="a"
                href="https://wa.me/+923322440544"
                target="_blank"
                sx={{ fontSize:'15px', fontWeight:'bold', color:'green' }}
            >
                <WhatsAppIcon /> Whatsapp Us
            </IconButton>
        </Box>
      );

    return (
        <AppBar position="sticky">
            <Container maxWidth="xxl" sx={{ backgroundColor: "#01411c" }}>
                <Toolbar disableGutters>
                    <HomeContainer>
                        <Typography
                            variant="h5"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <NavLogo
                                to="top"
                                activeClass="active"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onClick={homeHandler}
                            >
                                Alamkitab
                            </NavLogo>
                        </Typography>
                    </HomeContainer>

                    <HomeContainer>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 30,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <NavLogo
                                to="top"
                                activeClass="active"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onClick={homeHandler}
                            >
                                Alamkitab
                            </NavLogo>
                        </Typography>
                    </HomeContainer>

                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    {!isMobile && (
                        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                        {menuItems.map((item, index) => (
                            <Button
                            key={index}
                            onClick={item.onClick}
                            sx={{
                                color: "white",
                                background: "#006001",
                                borderRadius: "2px",
                                padding: "6px 16px",
                                fontSize: "14px",
                                fontWeight: "bold",
                                margin: "0 10px",
                                "&:hover": { backgroundColor: "#006001", transform: "scale(1.05)" },
                            }}
                            >
                            {item.text}
                            </Button>
                        ))}
                        <IconButton
                            component="a"
                            href="https://wa.me/+923322440544"
                            target="_blank"
                            sx={{ color: "white" }}
                        >
                            <WhatsAppIcon />
                        </IconButton>
                        </Box>
                    )}

                    {/* Mobile Menu */}
                    {isMobile && (
                        <>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            style={{marginLeft:'10px'}}
                            onClick={toggleDrawer(true)}
                        >
                            <MenuRounded />
                        </IconButton>
                        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                            {drawerMenu}
                        </Drawer>
                        
                        </>
                    )}

                    </Box>


                    {currentRole === null && (
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => navigate("/Customerlogin")} sx={{ my: 2, color: 'white', display: 'block' }}>
                                Sign in
                            </Button>
                        </Box>
                    )}

                    {currentRole === "Customer" && (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title="Cart">
                                    <IconButton onClick={handleOpenCart} sx={{ width: "4rem", color: 'inherit', p: 0 }}>
                                        <Badge badgeContent={totalQuantity} color="error">
                                            <ShoppingCartIcon sx={{ fontSize: "2rem" }} />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleOpenUserMenu}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-haspopup="true"
                                    >
                                        <Avatar sx={{ width: 32, height: 32, backgroundColor: "#006400" }}>
                                            {String(currentUser.name).charAt(0)}
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>

                                <Menu
                                    anchorEl={anchorElUser}
                                    id="menu-appbar"
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                    onClick={handleCloseUserMenu}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: styles.styledPaper,
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={() => navigate("/Profile")}>
                                        <Avatar />
                                        <Link to="/Profile">Profile</Link>
                                    </MenuItem>
                                    <MenuItem onClick={() => navigate("/Orders")}>
                                        <ListItemIcon>
                                            <Shop2 fontSize="small" />
                                        </ListItemIcon>
                                        <Link to="/Orders">My Orders</Link>
                                    </MenuItem>
                                    <MenuItem onClick={() => navigate("/print-history")}>
                                        <ListItemIcon>
                                        <PrintOutlined fontSize="small" />
                                        </ListItemIcon>
                                        <Link to="/print-history">Print Orders</Link>
                                    </MenuItem>
                                    <MenuItem onClick={() => navigate("/request-ur-document-for-printing")}>
                                        <ListItemIcon>
                                            <PrintOutlined fontSize="small" />
                                        </ListItemIcon>
                                        <Link to="/request-ur-document-for-printing">Print Your Document</Link>
                                    </MenuItem>
                                    <MenuItem onClick={() => navigate("/request-a-book")}>
                                        <ListItemIcon>
                                            <BookOnlineOutlined fontSize="small" />
                                        </ListItemIcon>
                                        <Link to="/request-a-book">Request A Book</Link>
                                    </MenuItem>
                                    <MenuItem onClick={() => navigate("/news")}>
                                        <ListItemIcon>
                                            <BookOnlineOutlined fontSize="small" />
                                        </ListItemIcon>
                                        <Link to="/news">News</Link>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={() => navigate("/Logout")}>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        <Link to="/Logout">Logout</Link>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Box>
                    )}
                </Toolbar>
            </Container>

            {isCartOpen && (
                <Drawer
                    anchor="right"
                    open={isCartOpen}
                    onClose={handleCloseCart}
                    sx={{
                        width: '400px',
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: '400px',
                            boxSizing: 'border-box',
                        },
                    }}
                >
                    <Cart setIsCartOpen={setIsCartOpen} />
                </Drawer>
            )}
        </AppBar>
    );
};

export default Navbar;

const HomeContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const styles = {
    styledPaper: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
};
