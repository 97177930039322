export const productDataList = [
  {
    productName: "SAMSUNG Galaxy S22 5G (Phantom White, 128 GB)  (8 GB RAM)",
    price: {
      mrp: 19999,
      cost: 14199,
      discountPercent: 30,
    },
    subcategory: 'Mobile Phones',
    productImage: "https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/5/r/x/-original-imagth5xf4shxcuv.jpeg?q=70",
    category: 'Electronics',
    description: "Explore a new range of night photography features on this Samsung Galaxy S22 5G smartphone. You can click stunning images even in low light with the Nightography mode. It is designed with a pro-grade camera which comprises a big pixel sensor that welcomes more light for mind-blowing photography. Built with impressive software and hardware, this phone is a game-changer. With a 120 Hz refresh rate and dynamic AMOLED 2x display, you are going to experience immersive screen time. This phone operates on a Snapdragon 8 Gen 1 processor that delivers quick and hassle- free navigation. This device is protected by Corning Gorilla Glass Victus and has an IP68 waterproof rating.",
    tagline: 'New Arrival',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "Xiaomi 12 Pro 5G (Opera Mauve, 256 GB)  (12 GB RAM)",
    price: {
      mrp: 19999,
      cost: 14199,
      discountPercent: 30,
    },
    subcategory: 'Mobile Phones',
    productImage: "https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/v/c/x/-original-imagqmtffg9sjjp6.jpeg?q=70",
    category: 'Electronics',
    description: "Treat your inner tech geek with this Xiaomi 12 Pro 5G mobile that surprises you with its flawless performance. With a 120 Hz, 10-bit 2K+, and AMOLED Display setup, you can show off your flair wherever you go. Your entertainment experience is upgraded with an AMOLED display that makes every image come to life. The quad speaker with sound by Harman/Kardon exhilarates you with its sound clarity. The flagship camera setup of this phone, with 50 MP + 50 MP + 50 MP, complements your photography skills. With the Pro Focus AI tracking technology, you can easily take clear pictures of moving objects. With the liquid cooling technology, your phone’s heat can be controlled even when you are multitasking. Unbeatable performance is achieved with the powerful Snapdragon 8 Gen 1 processor. Supported by a massive 4600 mAh battery and Surge P1 technology, you can witness an exceptional charging speed on this Xiaomi 12 Pro mobile.",
    tagline: 'New Arrival',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "REDMI Note 12 Pro 5G (Stardust Purple, 128 GB)  (6 GB RAM)",
    price: {
      mrp: 19999,
      cost: 14199,
      discountPercent: 30,
    },
    subcategory: 'Mobile Phones',
    productImage: "https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/r/o/o/-original-imaghkvuzxkcna4n.jpeg?q=70",
    category: 'Electronics',
    description: "The Redmi Note 12 Pro 5G has a tonne of incredible features and provides gorgeous photographs and a faultless user experience. Due to its 2 um pixel size, the 50 MP Sony IMX766 sensor on the Redmi Note 12 Pro 5G is able to capture more light and produce stunning low-light images. Additionally, this phone boasts an innovative OIS that works to reduce camera shake and provide steady, blur-free images, even in dim lighting conditions. An additional benefit of this phone is its 120 Hz Pro AMOLED display with adaptive sync, which provides a fluid, lively, and engaging user experience. You can enjoy this phone's MediaTek Dimesity 1080 processor efficiently since it has a big vapour chamber, a high CPU frequency, and compatibility with 10 5G bands.",
    tagline: 'New Arrival',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "ASUS Vivobook 15 Core i3 11th Gen 1115G4 - (8 GB/256 GB SSD/Windows 11 Home) X515EA-EJ312W Thin and Light Laptop  (15.6 Inch, Transparent Silver, 1.80 kg)",
    price: {
      mrp: 19999,
      cost: 14199,
      discountPercent: 30,
    },
    subcategory: 'Laptops',
    productImage: "https://rukminim2.flixcart.com/image/416/416/xif0q/computer/r/e/f/-original-imagrgprbhchwgve.jpeg?q=70",
    category: 'Electronics',
    description: "The outstanding Asus VivoBook 15 laptop, created to simplify your workday and improve performance, offers fantastic efficiency and stunning aesthetics. This laptop handles all of your multitasking requirements and gives you dynamic performance thanks to the Intel Core processor. Additionally, the dual-storage ASUS VivoBook 15 offers you the benefits of incredibly fast data speed and ample storage capacity. Moreover, its svelte and exquisite form makes you the centre of attention everywhere you go when donning it with you.",
    tagline: 'Limited Stock',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "DELL Core i5 12th Gen 1235U - (8 GB/512 GB SSD/Windows 11 Home) New Inspiron 15 Laptop Thin and Light Laptop  (14.96 inch, Carbon Black, 1.65 Kg, With MS Office)",
    price: {
      mrp: 19999,
      cost: 14199,
      discountPercent: 30,
    },
    subcategory: 'Laptops',
    productImage: "https://rukminim2.flixcart.com/image/416/416/xif0q/computer/n/o/s/-original-imaghzah9mjvukjz.jpeg?q=70",
    category: 'Electronics',
    description: "DELL Core i5 12th Gen 1235U - (8 GB/512 GB SSD/Windows 11 Home) New Inspiron 15 Laptop Thin and Light Laptop  (14.96 inch, Carbon Black, 1.65 Kg, With MS Office)",
    tagline: 'Limited Stock',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "ASUS ROG Strix G15 Ryzen 7 Octa Core AMD R7-4800H - (8 GB/512 GB SSD/Windows 11 Home/4 GB Graphics (15.6 Inch, Eclipse Gray, 2.10 Kg)",
    price: {
      mrp: 19999,
      cost: 14199,
      discountPercent: 30,
    },
    subcategory: 'Laptops',
    productImage: "https://rukminim2.flixcart.com/image/416/416/l3vxbbk0/computer/g/u/y/-original-imagewgtfgzf8fdd.jpeg?q=70",
    category: 'Electronics',
    description: "You can enjoy an immersive and engaging gaming experience on the ASUS ROG Strix G15 Gaming Laptop. With its focused power, this laptop streamlines and enhances the gaming experience on Windows 11 Home. Gaming and smooth, rapid multitasking are made possible by this laptop's AMD Ryzen 4000H-Series CPU and GeForce RTX 30-series GPU. You can play fast esports on this laptop as its display has a refresh rate of up to 144 Hz. When running heavy applications, this laptop's advanced thermal innovations help keep it cool. And, its Adaptive-Sync helps enhance the overall gaming experience.",
    tagline: 'Limited Stock',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "beatXP Marv Neo 1.85'' HD Display with Bluetooth Calling Smartwatch",
    price: {
      mrp: 6999,
      cost: 4049,
      discountPercent: 42,
    },
    subcategory: 'Smart Watches',
    productImage: 'https://rukminim2.flixcart.com/image/416/416/xif0q/smartwatch/a/0/j/4-6-bxio2003-android-ios-beatxp-yes-original-imagt9fsjarfyhfm.jpeg?q=70',
    category: 'Electronics',
    description: "High-performance and robust, the beatXP Marv Neo Smartwatch is an excellent addition to your watch collection. It lends high clarity on its up to 4.699 cm (1.85) wide TFT HD display. Additionally, its stylish screen with personalised watch faces offers an appealing appearance. Also, this smartwatch's EzyPair technology ensures a stable connection for hassle-free Bluetooth calling. Moreover, this smartwatch allows you to track your health indicators, such as calorie intake and more while participating in various fitness and sports activities.",
    tagline: 'Best Seller',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: 'NOVA NHP 8106 Hair Dryer  (1400 W, Black)',
    price: {
      mrp: 1899,
      cost: 1124,
      discountPercent: 40,
    },
    subcategory: 'Trimmers, Dryers & more',
    productImage: 'https://rukminim2.flixcart.com/image/416/416/khtghow0pkrrdj-0/hair-dryer/p/o/0/nhp-8106-nova-original-imafxtddk7rdercf.jpeg?q=70',
    category: 'Personal Care Appliances',
    description: 'The Nova Professional NHP 8220 Hair Dryer is a powerful and efficient hair styling tool. It operates at 1800 W, making it quick and effective in drying and styling your hair. The multicolor design adds a touch of style to your grooming routine. This hair dryer is perfect for both personal and professional use, offering salon-like results from the comfort of your home. Style your hair effortlessly with Nova Professional NHP 8220 Hair Dryer.',
    tagline: 'Kubra, Nova & more',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: 'Longway Speedy 300 mm Energy Saving 3 Blade Table Fan  (Black, Pack of 1)',
    price: {
      mrp: 2250,
      cost: 1199,
      discountPercent: 46,
    },
    subcategory: 'Table Fans',
    productImage: 'https://rukminim2.flixcart.com/image/416/416/xif0q/fan/s/i/r/speedy-230-1-table-fan-300-longway-original-imagpgp5kzufdcxj.jpeg?q=70',
    category: 'Home & Kitchen',
    description: 'Table Fan. Perfect size fan for use on a table, desk or in an RV. Whisper quiet, powerful airflow and reliable operation in a compact 6" size. Two adjustable speeds to customize airflow: high or low settings. Tough break-resistant ABS plastic blades. ',
    tagline: 'Top Selling',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: 'boAt Rockerz 255 Pro+ /258 Pro+ with ASAP Charge and upto 60 Hours Playback Bluetooth Headset  (Active Black, In the Ear)',
    price: {
      mrp: 2990,
      cost: 1199,
      discountPercent: 59,
    },
    subcategory: 'Headphones',
    productImage: 'https://rukminim2.flixcart.com/image/416/416/l31x2fk0/headphone/a/s/h/-original-image9ehehz8amg2.jpeg?q=70',
    category: 'Electronics',
    description: 'boAt Rockerz 255 Pro+ is a power-packed in-ear wireless neckband headphone that has been ergonomically designed to meet the needs of music lovers. The headphones come equipped with Bluetooth V5.0 for instant wireless connectivity. Its super powerful 300mAh battery provides a humongous playtime of up to 60 hours for an extended audio bliss. Its 10mm dynamic drivers help supply superior musical experience to the user with immersive sound. With our ASAP fast charge technology, you can get the headphones fully charged in just 60 minutes. It also offers resistance against water and sweat with IPX7 rated premium build. One can control the playback and calls easily via the provided multifunction controls and even summon respective smart Voice Assistant. Get ready to get enthralled by the pumped up audio and soaring vibe of Rockerz 255 Pro+',
    tagline: 'Grab Now!',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "SAMSUNG Galaxy F13 (Sunrise Copper, 64 GB)  (4 GB RAM)",
    price: {
      mrp: 14999,
      cost: 9199,
      discountPercent: 38,
    },
    subcategory: 'Mobile Phones',
    productImage: "https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/x/x/s/-original-imagfhu6dcpdnqkh.jpeg?q=70",
    category: 'Electronics',
    description: 'This smartphone features a terrific 16.62 cm (6.6) FHD+ LCD Display that can effortlessly blow your mind with its incredible performance. Furthermore, this phone boasts a 50 MP Triple Camera setup that allows you to capture awesomeness with a gentle tap. Moreover, the Samsung Galaxy F13 sports up to 8 GB of RAM and features an innovative RAM plus technology that taps into the phone’s internal storage to elevate its performance.',
    tagline: 'New Arrival',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "APPLE iPhone 13 (Midnight, 128 GB)",
    price: {
      mrp: 19999,
      cost: 14199,
      discountPercent: 30,
    },
    subcategory: 'Mobile Phones',
    productImage: "https://rukminim2.flixcart.com/image/416/416/ktketu80/mobile/s/l/c/iphone-13-mlpf3hn-a-apple-original-imag6vzz5qvejz8z.jpeg?q=70",
    category: 'Electronics',
    description: 'iPhone 13. boasts an advanced dual-camera system that allows you to click mesmerising pictures with immaculate clarity. Furthermore, the lightning-fast A15 Bionic chip allows for seamless multitasking, elevating your performance to a new dimension. A big leap in battery life, a durable design, and a bright Super Retina XDR display facilitate boosting your user experience.',
    tagline: 'New Arrival',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "NIKON Z 50 Mirrorless Camera Body with 16-50mm Lens (Black)",
    price: {
      mrp: 1200,
      cost: 840,
      discountPercent: 30,
    },
    subcategory: 'Cameras',
    productImage: "https://rukminim2.flixcart.com/image/312/312/k5o7r0w0/dslr-camera/z/h/4/z-50-z-50-nikon-original-imafzasjz33kaa8k.jpeg?q=70",
    category: 'Electronics',
    description: 'Capture stunning photos and videos with this high-resolution digital camera. Perfect for photography enthusiasts.',
    tagline: 'Best Seller',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: 'Prestige PKOSS Electric Kettle (1.5 L, Silver, Black)',
    price: {
      mrp: 1195,
      cost: 717,
      discountPercent: 40,
    },
    subcategory: 'Electric Kettles',
    productImage: 'https://rukminim2.flixcart.com/image/612/612/kjlrb0w0/electric-kettle/g/h/w/prestige-prestige-pkoss-pkoss-1-5-original-imafz4wtzxhkau3g.jpeg?q=70',
    category: 'Home & Kitchen',
    description: 'This electric kettle from Pigeon will soon become a travelers best friend, a hostelite saviour and an answer to all the midnight cravings. With this handy appliance, you can boil water and use it to make instant noodles, packet soup, coffee and green tea.',
    tagline: 'Deal of the day',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: 'Men Printed Round Neck Cotton Blend White T-Shirt',
    price: {
      mrp: 499,
      cost: 166,
      discountPercent: 66,
    },
    subcategory: 'T-Shirts',
    productImage: 'https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/f/i/j/s-twtrnful-d108-tripr-original-imags8h2wffceyvh.jpeg?q=70',
    category: 'Sports & Fitness',
    description: 'Stay cool and stylish with this really cool T-Shirt',
    tagline: 'Deal of the Day',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "POCO C51 (Power Black, 64 GB)  (4 GB RAM)",
    price: {
      mrp: 19999,
      cost: 14199,
      discountPercent: 30,
    },
    subcategory: 'Mobile Phones',
    productImage: "https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/o/t/a/c51-mzb0e6din-poco-original-imagzdzzraqzsrzc.jpeg?q=70",
    category: 'Electronics',
    description: "Give your inner tech nerd a treat with the POCO C51 smartphone, which mesmerises you from the minute you switch it on thanks to its top-notch performance. The POCO C51's MediaTek Helio G36 octa-core Processor, clocked at up to 2.2 GHz, has all the power needed for responsive web surfing and multimedia tasks. Also, the POCO C51 includes a fantastic Turbo RAM function that allows you access to up to 7 GB of RAM whenever you need it. This feature enables you to play visually demanding games and easily consume high-quality material. Also, the big 16.56 cm (6.52) HD+ display on this phone is perfect for all of your surfing and gaming demands. Also, the POCO C51 smartphone has an 8 MP dual camera and a 5 MP selfie camera that allows you to record the enthralling moments in your daily life with sharp images.",
    tagline: 'New Arrival',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "vivo T2x 5G (Aurora Gold, 128 GB)  (8 GB RAM)",
    price: {
      mrp: 19999,
      cost: 14199,
      discountPercent: 30,
    },
    subcategory: 'Mobile Phones',
    productImage: "https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/c/s/x/-original-imagzjhwaaewgj8r.jpeg?q=70",
    category: 'Electronics',
    description: "With the superb Vivo T2x 5G, you can take advantage of great pictures and a flawless user experience. With the Vivo T2x 5G, you can experience exceptional performance owing to its 7 nm 5G CPU, the octa-core Dimensity 6020, with a top clock speed of 2.2 GHz. Additionally, the 50 MP main camera on this smartphone beautifully catches every detail you see. Additionally, Super Night Selfie employs noise cancellation technology in conjunction with an Aura Screen Light to produce a calming light that is effective in low light. The pioneering Extended RAM 3.0 technology also uses ROM to expand RAM with a maximum capacity of 8 GB. This enables smooth app switching and allows up to 27 active applications to run in the background.",
    tagline: 'New Arrival',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
  {
    productName: "APPLE iPhone 14 (Blue, 128 GB)",
    price: {
      mrp: 69999,
      cost: 64199,
      discountPercent: 30,
    },
    subcategory: 'Mobile Phones',
    productImage: "https://picsum.photos/536/354",
    category: 'Electronics',
    description: "Best smart phone under this price range compare to other phones in 2023 if you see overall build quality, performance and Camera with autofocus and video action mode are awesome 50% extra RAM compared to iPhone 13 and other more features. Best time to upgrade to iPhone 14 . ",
    tagline: 'New Arrival',
    quantity: 1,
    seller: "652ebf61b409f906d81645a3",
  },
];

export const bannerData = [
  { _id: 1, url: 'https://icms-image.slatic.net/images/ims-web/f955ccf9-a43a-445f-a1a9-63462e7e6459.jpg_1200x1200.jpg' },
  { _id: 2, url: 'https://icms-image.slatic.net/images/ims-web/d47cbf86-a9e3-4aa9-b33f-5b0ed45c63b1.jpeg' },
  { _id: 3, url: 'https://icms-image.slatic.net/images/ims-web/cfde0167-148a-49f1-8dcf-d4f4c3b6da8b.jpg' },
  { _id: 4, url: 'https://icms-image.slatic.net/images/ims-web/3adf9361-0347-4449-87a5-17ee573d65cc.jpg' },
]