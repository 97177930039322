import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Snackbar,
  Alert,
  TextField,
  MenuItem,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SellerNewsManagement = () => {
  const [newsList, setNewsList] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    category: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const categories = ["Admissions", "Exams & Results", "Conferences & Events", "Book Reviews"];

  useEffect(() => {
    fetch("https://backend.alamkitab.com/api/news")
      .then((res) => res.json())
      .then((data) => setNewsList(data))
      .catch((error) => console.error("Error fetching news:", error));
  }, []);

  const handleDelete = (id) => {
    fetch(`https://backend.alamkitab.com/api/news/${id}`, { method: "DELETE" })
      .then((res) => {
        if (res.ok) {
          setNewsList((prev) => prev.filter((news) => news._id !== id));
          setSnackbarMessage("News deleted successfully!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to delete news.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        console.error("Error deleting news:", error);
      });
  };

  const handleEdit = (news) => {
    setSelectedNews(news);
    setFormData({
      title: news.title,
      description: news.description,
      category: news.category,
    });
    setOpenDialog(true);
  };

  const handleAdd = () => {
    setSelectedNews(null);
    setFormData({ title: "", description: "", category: "" });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setFormData({ title: "", description: "", category: "" });
  };

  const handleSave = () => {
    const url = selectedNews
      ? `https://backend.alamkitab.com/api/news/${selectedNews._id}`
      : "https://backend.alamkitab.com/api/news";

    const method = selectedNews ? "PUT" : "POST";

    fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (selectedNews) {
          setNewsList((prev) =>
            prev.map((news) => (news._id === data._id ? data : news))
          );
        } else {
          setNewsList((prev) => [data, ...prev]);
        }
        handleDialogClose();
        setSnackbarMessage(selectedNews ? "News updated successfully!" : "News added successfully!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setSnackbarMessage("Failed to save news.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        console.error("Error saving news:", error);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center" }}
      >
        Manage News
      </Typography>

      {/* <Button
        variant="contained"
        sx={{ mb: 2 }}
        onClick={handleAdd}
      >
        Add News
      </Button> */}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newsList.map((news) => (
              <TableRow key={news._id}>
                <TableCell>{news.title}</TableCell>
                <TableCell>{news.category}</TableCell>
                <TableCell>{new Date(news.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(news)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(news._id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>{selectedNews ? "Edit News" : "Add News"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            fullWidth
            margin="dense"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          <Typography sx={{ mt: 2, mb: 1 }}>Description</Typography>
          <ReactQuill
            theme="snow"
            value={formData.description}
            onChange={(value) => setFormData({ ...formData, description: value })}
          />
          <TextField
            select
            label="Category"
            fullWidth
            margin="dense"
            value={formData.category}
            onChange={(e) => setFormData({ ...formData, category: e.target.value })}
          >
            {categories.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SellerNewsManagement;
