import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSpecificProducts } from '../../../redux/userHandle';
import {
  Container,
  Typography,
  Stack,
  Button,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  Box,
} from '@mui/material';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useTheme } from '@mui/material/styles';

const CustomerOrders = () => {
  const dispatch = useDispatch();
  const { currentUser, loading, specificProductData } = useSelector((state) => state.user);

  const [open, setOpen] = useState(null);
  const [selectedOption, setSelectedOption] = useState('newest');
  const [sortedProductData, setSortedProductData] = useState([]);
  const [allPoints, setAllPoints] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (currentUser) {
      dispatch(getSpecificProducts('getOrderedProductsByCustomer', currentUser._id));
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    const totalPoints = sortedProductData.reduce(
      (acc, order) => acc + (order?.loyaltyPoints || 0),
      0
    );
    setAllPoints(totalPoints);
  }, [sortedProductData]);

  useEffect(() => {
    if (specificProductData && specificProductData.length > 0) {
      const sortedData = [...specificProductData].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return selectedOption === 'newest' ? dateB - dateA : dateA - dateB;
      });
      setSortedProductData(sortedData);
    }
  }, [specificProductData, selectedOption]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMenuItemClick = (value) => {
    setSelectedOption(value);
    handleClose();
  };

  const handleDownloadPDF = (order) => {
    const doc = new jsPDF();

    // Add heading
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(22);
    doc.text('Alamkitab', 105, 20, { align: 'center' });

    // Add subtitle
    doc.setFontSize(18);
    doc.text('Order Invoice', 105, 30, { align: 'center' });

    // Set general order details
    doc.setFontSize(12);
    doc.setFont('Helvetica', 'normal');
    doc.text(`Order Date: ${new Date().toLocaleDateString()}`, 10, 50);
    doc.text(`Order ID: ${order.orderNumber}`, 10, 60);
    doc.text(`Customer Name: ${currentUser.name}`, 10, 70);
    doc.text(`Email: ${currentUser.email}`, 10, 80);
    doc.text(`Mobile: ${currentUser.shippingData.phoneNo}`, 10, 90);
    doc.text(`Shipping Address: ${currentUser.shippingData.address}, ${currentUser.shippingData.city}, ${currentUser.shippingData.state}`, 10, 100);

    // Add table using autoTable plugin
    autoTable(doc, {
      startY: 110,
      head: [['Book', 'Quantity', 'Price', 'Total']],
      body: order.orderedProducts.map(item => [
        item.productName,
        item.quantity,
        `${item.price?.cost}`,
        `${item.quantity * item.price?.cost}`,
      ]),
    });

    const totalPriceWithoutShipping = order.orderedProducts.reduce(
      (acc, item) => acc + item.quantity * item.price.cost,
      0
    );
    const shippingFee = 100; // Example shipping fee
    const totalPriceWithShipping = totalPriceWithoutShipping + shippingFee;

    const yPosition = doc.lastAutoTable.finalY + 10;

    // Add order summary
    doc.text(`Mode of Payment: Cash on Delivery`, 10, yPosition);
    doc.text(`Estimated delivery date: 4-5 days`, 10, yPosition + 10);
    doc.text(`Total Price (without shipping): Rs ${totalPriceWithoutShipping}`, 10, yPosition + 20);
    doc.text(`Shipping Fee: Rs ${shippingFee}`, 10, yPosition + 30);
    doc.text(`Total Price (with shipping): Rs ${totalPriceWithShipping}`, 10, yPosition + 40);

    // Footer
    doc.text(`Contact us: contactus@alamkitab.com`, 10, yPosition + 60);
    doc.text(`WhatsApp us: +92 332 2440544`, 10, yPosition + 70);

    // Save the PDF
    doc.save(`order-${order.orderNumber}.pdf`);
  };

  return (
    <>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Container sx={{ padding: 4 }}>
          <Typography sx={{ fontSize: 40, textAlign: 'center', mb: 4 }}>My Orders</Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ mb: 4 }}
          >
            <Typography sx={{
              mr:3,
              mb:0.4
            }}>
              Total Loyalty Points {allPoints}
            </Typography>
            <Button color="inherit" onClick={handleOpen}>
              Sort By: {selectedOption === 'newest' ? 'Newest' : 'Oldest'}
            </Button>
            <Menu
              anchorEl={open}
              open={!!open}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={() => handleMenuItemClick('newest')}>Newest</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('oldest')}>Oldest</MenuItem>
            </Menu>
            
          </Stack>

          {sortedProductData && sortedProductData.length > 0 ? (
            <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', mb: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Order Status</TableCell>
                    <TableCell>Loyalty Points</TableCell>
                    <TableCell>Download Invoice</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedProductData.map((order) => {
                    
                    return(
                    <TableRow key={order._id}>
                      <TableCell>{order.orderNumber}</TableCell>
                      <TableCell>
                        {order.orderedProducts.map(product => (
                          <div key={product._id}>
                            {product.productName} (Qty: {product.quantity})
                          </div>
                        ))}
                      </TableCell>
                      <TableCell>
                        {order.orderedProducts.map(product => (
                          <div key={product._id}>{product.price?.cost}</div>
                        ))}
                      </TableCell>
                      <TableCell>{order.orderStatus}</TableCell>
                      <TableCell>
                        <div  style={{
                          backgroundColor:"#006001",
                          color:"white",
                          width:"fit-content",
                          padding:"2px 10px",
                          borderRadius:20,
                          // margin:"auto"
                        }}>

                        {order?.loyaltyPoints}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => handleDownloadPDF(order)}>
                          Download Invoice
                        </Button>
                      </TableCell>
                    </TableRow>
                  )})}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No Orders Till Now</Typography>
          )}
        </Container>
      )}
    </>
  );
};

export default CustomerOrders;
