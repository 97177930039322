import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCustomers } from '../../../redux/userHandle.js';
import { Typography, Box, Card, Divider, Button } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ShowOrders = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const customerID = params.id;

    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await fetch('https://backend.alamkitab.com/getAllCustomers');
                if (!response.ok) throw new Error('Failed to fetch customers');

                const data = await response.json();
                setCustomers(data);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchCustomers();
    }, []);

    useEffect(() => {
        dispatch(getCustomers(customerID, "getOrderedProductsByCustomer"));
    }, [customerID, dispatch]);

    const { loading, customersList, responseCustomersList } = useSelector(state => state.user);

    const getCustomerDetails = (customerId) => {
        const customer = customers.find(c => c.customerID === customerId);
        return customer ? { name: customer.customerName, email: customer.customerEmail } : { name: 'Unknown', email: 'Unknown' };
    };

    const filterOrders = (orders) => {
        if (!Array.isArray(orders) || orders.length === 0) return [];
        return orders.filter(order =>
            order.orderStatus === "Processing" ||
            order.orderStatus === "Packed" || // Include Packed status
            order.orderStatus === "Shipped" ||
            order.orderStatus === "Delivered"
        );
    };

    const sortOrdersByDate = (orders) => {
        return orders.sort((a, b) => new Date(b.paidAt) - new Date(a.paidAt));
    };

    const filteredOrders = sortOrdersByDate(filterOrders(customersList));

    const downloadPDF = (order, customerDetails) => {
        const doc = new jsPDF();
        // Add title
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(22);
        doc.text('Alamkitab', 105, 20, { align: 'center' });
        doc.setFontSize(18);
        doc.text('Order Invoice', 105, 30, { align: 'center' });

        // Customer and order details
        doc.setFont('Helvetica', 'normal');
        doc.setFontSize(12);
        doc.text(`Order ID: ${order.orderNumber}`, 20, 50);
        doc.text(`Customer Name: ${customerDetails.name}`, 20, 70);
        doc.text(`Email: ${customerDetails.email}`, 20, 80);
        doc.text(`Mobile: ${order.shippingData.phoneNo}`, 20, 90);
        doc.text(`Shipping Address: ${order.shippingData.address}, ${order.shippingData.city}, ${order.shippingData.state}`, 20, 100);

        // Add the products table
        doc.autoTable({
            startY: 110,
            head: [['Product', 'Quantity', 'Price', 'Total']],
            body: order.orderedProducts.map(product => [
                product.productName,
                product.quantity,
                product.price?.cost,
                product.price?.cost * product.quantity
            ]),
            theme: 'striped',
            styles: {
                fontSize: 10,
                cellPadding: 5,
                overflow: 'linebreak',
                halign: 'center'
            }
        });

        // Pricing details
        const totalCost = order.orderedProducts.reduce((acc, product) => acc + product.price.cost * product.quantity, 0) + 100;
        let finalY = doc.lastAutoTable.finalY + 10;
        doc.setFontSize(14);
        doc.text(`Total Price (With Shipping): Rs ${totalCost}`, 20, finalY);

        // Save the PDF
        doc.save(`order_${order.orderNumber}.pdf`);
    };

    return (
        <>
            {loading ? (
                <h1>Loading...</h1>
            ) : (
                <>
                    {responseCustomersList ? (
                        <h1>No Orders Found</h1>
                    ) : (
                        <>
                            <Typography variant="h5" gutterBottom>
                                Orders List:
                            </Typography>

                            {Array.isArray(filteredOrders) && filteredOrders.length > 0 ? (
                                filteredOrders.map((order) => {
                                    const customerDetails = getCustomerDetails(order.buyer);

                                    return (
                                        <Box key={order._id} mb={4}>
                                            <Card sx={{ padding: '20px', marginBottom: '20px' }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Customer Name: {customerDetails.name}
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Email: {customerDetails.email}
                                                </Typography>
                                                <Typography variant="h6" gutterBottom>
                                                    Order Number: {order.orderNumber}
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Order ID: {order._id}
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Status: {order.orderStatus}
                                                </Typography>

                                                <Typography variant="body1">
                                                    Products:
                                                </Typography>
                                                {order.orderedProducts.map((product) => (
                                                    <CardBox key={product._id}>
                                                        <Typography variant="body1">
                                                            {product.productName} - Quantity: {product.quantity}
                                                        </Typography>
                                                    </CardBox>
                                                ))}

                                                <Typography variant="body1" color="textSecondary" gutterBottom>
                                                    Ordered At: {new Date(order.paidAt).toLocaleString()}
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary" gutterBottom>
                                                    Phone Number: {order.shippingData.phoneNo}
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary" gutterBottom>
                                                    Shipping Address: {order.shippingData.address}, {order.shippingData.city}, {order.shippingData.state}, {order.shippingData.pinCode}
                                                </Typography>

                                                <Typography variant="body1" color="textSecondary">
                                                    Total Cost: Rs {order.orderedProducts.reduce((acc, product) => acc + product.price.cost * product.quantity, 0) + 100}
                                                </Typography>
                                                <Button variant="contained" color="primary" onClick={() => downloadPDF(order, customerDetails)}>
                                                    Download PDF
                                                </Button>
                                            </Card>

                                            <Divider />
                                        </Box>
                                    );
                                })
                            ) : (
                                <Typography variant="h6">No Orders Found</Typography>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

const CardBox = ({ children }) => {
    return (
        <Card 
            sx={{ 
                padding: '10px', 
                margin: '10px 0', 
                display: 'flex', 
                fontWeight: 'bold',
                justifyContent: 'center', 
                alignItems: 'center', 
                backgroundColor: '#f5f5f5',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' 
            }}
        >
            {children}
        </Card>
    );
};

export default ShowOrders;
