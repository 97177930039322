import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Paper, Typography, Avatar, Container, Button } from '@mui/material';
import ShippingPage from '../components/ShippingPage';

const Profile = () => {
  const { currentUser } = useSelector(state => state.user);

  return (
    <React.Fragment>
      <ProfileContainer>
        <ProfileHeader elevation={3}>
          <ProfileAvatar>
            {currentUser ? currentUser.name[0].toUpperCase() : ''}
          </ProfileAvatar>
          <ProfileName variant="h4">
            {currentUser ? currentUser.name : 'Guest'}
          </ProfileName>
          <ProfileText variant="body1">
            Email: {currentUser ? currentUser.email : 'Not available'}
          </ProfileText>
          <ProfileText variant="body1">
            Role: {currentUser ? currentUser.role : 'Not specified'}
          </ProfileText>
        </ProfileHeader>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <ShippingSection variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <ShippingPage profile="Profile" />
        </ShippingSection>
      </Container>
      </ProfileContainer>

    </React.Fragment>
  );
};

export default Profile;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #e7f3fe; /* Light background for better contrast */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ProfileHeader = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff; /* White background for a clean look */
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensure it takes full width */
  max-width: 400px; /* Limit max width for better readability */
`;

const ProfileAvatar = styled(Avatar)`
  padding: 30px;
  background-color: #006004;
  margin-bottom: 10px;
  font-size: 2.5rem; /* Increase font size for better visibility */
`;

const ProfileName = styled(Typography)`
  padding: 10px 0;
`;

const ProfileText = styled(Typography)`
  margin-bottom: 10px;
  color: #555; /* Darker text for better contrast */
`;

const EditButton = styled(Button)`
  margin-top: 20px; /* Space between text and button */
`;

const ShippingSection = styled(Paper)`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;
